import React, { useState } from "react";
import { FaRegCopy } from "react-icons/fa";

import { css } from "@emotion/react";
import { RotateLoader } from "react-spinners";

import api from "../api/config";

import Container from "../components/Container";

const MainPage = () => {
  const [message, setMessage] = useState("");
  const [urlId, setUrlId] = useState("");
  const [url, setUrl] = useState("");
  const [copied, setCopied] = useState(false);
  const [deleted, setDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const letsEncrypt = async (e) => {
    e.preventDefault();

    const data = { message, urlId, url };

    try {
      setDeleted(false);
      setCopied(false);
      setLoading(true);
      const response = await api.post("encrypt", data);
      setLoading(false);

      setUrlId(response.data.urlId);
      setUrl(response.data.url);

      setMessage("");
    } catch (error) {
      console.error(error);
    }
  };

  const handleDelete = async () => {
    await api
      .delete(`encrypt/${urlId}`)
      .then((res) => {
        setDeleted(true);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(url)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying text: ", error));
  };

  return (
    <Container>
      <section className="flex flex-col justify-center items-center lg:max-w-5xl lg:min-h-[110vh]">
        <div className="flex flex-col items-center text-center lg:justify-center">
          <div className="lg:w-10/12">
            <h1 className="text-4xl p-2 m-2 text-white">
              Secure way to share notes, passwords, or whatever you want for
              Free.
            </h1>
            <h2 className="text-white">
              Encrypt and send messages with a link that automatically destruct
              after being read. Safe Whisper is a free web-based service that
              allows you to share a note with confidentiality. Don't let hackers
              get you.
            </h2>
          </div>
          <div className="w-11/12 rounded-2xl p-2 my-12 shadow-2xl bg-slate-600 shadow-slate-200">
            <h3 className="text-2xl text-white">Create Your Private Message</h3>
            <h4 className="text-white p-2">
              Write the message below, encrypt it, and get a link
            </h4>
            <form
              className="flex flex-col justify-center items-center"
              onSubmit={letsEncrypt}
            >
              <textarea
                onChange={(e) => setMessage(e.target.value)}
                rows={5}
                value={message}
                className="border-2 border-slate-900 p-4 w-full lg:h-56 md:w-8/17 md:h-6/12"
                name="message"
                id="message"
                placeholder="Your Message Here"
              ></textarea>

              {loading && (
                <div className="m-10">
                  <RotateLoader
                    css={css`
                      display: block;
                      margin: 0 auto;
                    `}
                    size={12}
                    color={"#058495"}
                    loading={loading}
                  />
                </div>
              )}
              <button
                onCopy={() => {
                  setCopied(true);
                }}
                className="bg-primaryColor rounded-full p-3 text-white w-52 mt-8 hover:opacity-80"
              >
                Encrypt My Message
              </button>
            </form>
            {url && (
              <>
                <div className="flex flex-col lg:flex-row justify-center items-center lg:items-end my-6">
                  <span
                    value={url}
                    className="lg:mr-6 text-primaryColor bg-white px-4 py-1 rounded-lg"
                  >
                    {url}
                  </span>
                  <button
                    onClick={handleCopyToClipboard}
                    className="text-white mt-4 lg:mt-0 hover:opacity-30"
                  >
                    <FaRegCopy className="text-3xl mt-4" />
                  </button>
                  {copied ? (
                    <span className="text-white mt-4 lg:mt-0 lg:ml-2">
                      Copied!
                    </span>
                  ) : null}
                </div>
                <div className="flex flex-col justify-center items-center">
                  <button
                    onClick={handleDelete}
                    className="bg-red-900 rounded-full text-white w-52 mt-8 hover:opacity-80"
                  >
                    Destroy it Now
                  </button>
                  {deleted ? (
                    <span className="p-12 text-white">Message Deleted</span>
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </section>
      <section className="w-11/12 m-auto rounded-2xl bg-white my-4 p-6 flex flex-col justify-center items-center">
        <h2 className="text-3xl text-center p-4">
          Why sending encrypted messages or messages that will self destruct
          after being opened and viewed?
        </h2>
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2">
            <p className="m-4 bg-erGray text-white border-2 border-black p-4 rounded lg:h-60 leading-7 text-center">
              In today's digital age, sending encrypted messages has become
              increasingly important. With the rise of cybercrime and hacking,
              it is more critical than ever to protect sensitive information.
              Encryption ensures that only the intended recipient can access and
              read the message, making it a vital tool for secure communication.
              By encrypting messages, the information is encoded in a way that
              can only be accessed by the intended recipient, ensuring that the
              information stays secure and private.
            </p>
            <p className="m-4 bg-erGray text-white border-2 border-black p-4 rounded lg:h-56 leading-7 text-center">
              Self-destructing messages can be especially useful for businesses
              and individuals who handle highly confidential information on a
              regular basis. This can include legal documents, financial
              statements, and personal data, among others. By utilizing
              self-destructing messages, businesses and individuals can ensure
              that their sensitive information is kept safe from prying eyes and
              that they maintain their reputation as trusted and secure
              entities.
            </p>
            <p className="m-4 bg-erGray text-white border-2 border-black p-4 rounded lg:h-56 leading-7 text-center">
              Sending encrypted messages that self-destruct after being viewed
              once can be incredibly important for ensuring the confidentiality
              and security of sensitive information. In today's digital age, the
              transmission of information is becoming increasingly vulnerable to
              hackers and other security threats. By using encrypted messaging,
              users can feel confident that their messages are secure and
              protected from prying eyes.
            </p>
          </div>
          <div className="w-full lg:w-1/2">
            <p className="m-4 bg-erGray text-white border-2 border-black p-4 rounded lg:h-60 leading-7 text-center">
              Self-destruct feature adds an extra layer of security by ensuring
              that the message is only visible once. This means that even if the
              message is intercepted by unauthorized individuals, they will not
              be able to access it after it has been viewed by the intended
              recipient. This helps to prevent any potential leaks of sensitive
              information and minimizes the risk of data breaches.
            </p>
            <p className="m-4 bg-erGray text-white border-2 border-black p-4 rounded lg:h-56 leading-7 text-center">
              Sending encrypted messages is a crucial aspect of secure
              communication in today's digital world. Not only does it protect
              sensitive information and privacy but also businesses can build
              trust with customers, improve their website's ranking, and avoid
              costly fines and legal action. Overall, encrypted messaging is an
              essential tool for any individual or business looking to
              communicate securely and protect sensitive information.
            </p>
            <ol className="m-4 bg-erGray text-white border-2 border-black p-4 rounded min-lg:h-56 leading-7">
              <li>
                <span className="underline font-bold">Confidentiality:</span>{" "}
                Encryption ensures that the message can only be read by the
                intended recipient, ensuring confidentiality.
              </li>
              <li>
                <span className="underline font-bold">Security:</span>{" "}
                Encryption provides an extra layer of security by encoding the
                message in a way that can only be decrypted by the intended
                recipient.
              </li>
              <li>
                <span className="underline font-bold">Privacy:</span> Encrypted
                messages help protect the privacy of the sender and the
                recipient by ensuring that the message cannot be intercepted or
                read by unauthorized individuals.
              </li>
              <li>
                <span className="underline font-bold">Authenticity:</span>{" "}
                Encryption can help verify the authenticity of the message by
                confirming that it was sent by the intended sender.
              </li>
              <li>
                <span className="underline font-bold">Integrity:</span>{" "}
                Encrypted messages can help ensure the integrity of the message
                by confirming that it has not been tampered with or altered
                during transmission.
              </li>
            </ol>
          </div>
        </div>
      </section>
    </Container>
  );
};
export default MainPage;
