import React from "react";

import logo from "../assets/logo-square.png";
import { Link } from "react-router-dom";

import Navbar from "./Navbar";

const Container = ({ children, className }) => {
  return (
    <>
      <Navbar />
      <div
        className={
          "flex flex-col justify-center items-center w-full min-h-screen bg-secondaryColor " +
          className
        }
      >
        <Link to="/">
          <img src={logo} alt="Logo" className="lg:hidden" />
        </Link>
        {children}
      </div>
    </>
  );
};
export default Container;
