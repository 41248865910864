import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useParams } from "react-router-dom";

import api from "../api/config";
import { FaRegCopy } from "react-icons/fa";
import Container from "../components/Container";

const MessagePage = () => {
  const [message, setMessage] = useState("");
  const [blur, setBlur] = useState(true);
  const [copied, setCopied] = useState(false);
  const [deleted, setDeleted] = useState(false);

  let navigate = useNavigate();

  const { urlId } = useParams();

  const readContent = () => {
    const getDecryptedContent = async () => {
      try {
        const response = await api.get(`encrypt/${urlId}`);
        setMessage(response.data.decryptedMessage);
      } catch (err) {
        console.error(err);
      }
    };
    getDecryptedContent();
    api
      .delete(`encrypt/${urlId}`)
      .then((res) => {
        setDeleted(true);
      })
      .catch((e) => {
        console.log(e);
      });
    setBlur(false);

    setTimeout(() => {
      navigate("/");
    }, 60000);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard
      .writeText(message)
      .then(() => setCopied(true))
      .catch((error) => console.error("Error copying text: ", error));
  };

  return (
    <>
      <Container>
        <h1 className="p-2 text-white text-3xl">Your Private Message</h1>
        <h2 className="m-6 text-white">
          Your message will destroyed once you read it.
        </h2>
        <div className={`flex gap-4 justify-center items-center`}>
          <div className="flex flex-col justify-center items-center w-screen">
            {message ? (
              <textarea
                rows={10}
                className={`border border-slate-800 bg-white p-4 w-96 lg:w-[800px] ${
                  blur ? "blur-sm" : ""
                }`}
              >
                {message}
              </textarea>
            ) : (
              <p
                className={`border border-slate-800 bg-white p-4 w-96 lg:w-[800px] h-72 lg:h-72 select-none ${
                  blur ? "blur-sm" : ""
                }`}
              >
                Sorry, this message was destroyed...
              </p>
            )}
            <div className="flex">
              <button
                onClick={handleCopyToClipboard}
                className={`text-white ${!blur ? "hover:opacity-30" : ""}`}
              >
                <FaRegCopy className="text-3xl mt-4" />
              </button>
              {copied ? (
                <span className="lg:ml-2 mt-4 text-white">Copied!</span>
              ) : null}
            </div>
          </div>
        </div>
        <button
          className="bg-primaryColor rounded-full p-3 text-white w-52 mt-8 hover:opacity-80"
          onClick={readContent}
        >
          Read Content
        </button>
        {deleted ? (
          <span className="text-white m-4">Message destroyed...</span>
        ) : null}
      </Container>
    </>
  );
};
export default MessagePage;
