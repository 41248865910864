import React, { useState } from "react";
import { Link } from "react-router-dom";

import logo from "../assets/logo-square.png";
import { FaBars, FaTimes } from "react-icons/fa";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [color, setColor] = useState(false);
  const closeNav = () => setIsOpen(false);

  const changeColor = () => {
    if (window.scrollY >= 90) {
      setColor(true);
    } else {
      setColor(false);
    }
  };

  window.addEventListener("scroll", changeColor);

  return (
    <>
      <section className="hidden lg:block text-primaryColor bg-secondaryColor">
        <div
          className={
            isOpen
              ? "inset-0 fixed z-10 bg-secondaryColor opacity-50 duration-700"
              : "opacity-0 duration-700"
          }
          onClick={() => setIsOpen(false)}
        ></div>
        <nav className="">
          <div className="logo flex justify-center items-center"></div>
          <div
            className={
              isOpen
                ? "fixed bg-secondaryColor w-full h-screen left-0 top-0 z-50 flex flex-col items-center justify-start text-2xl translate-x-0 duration-700 md:w-3/12 md:translate-x-[300%]"
                : "lg:flex lg:flex-row lg:gap-8 lg:h-20 lg:mr-12 lg:duration-0 lg:bg-secondaryColor lg:fixed lg:-left-full fixed bg-secondaryColor w-full h-screen left-0 top-0 z-50 flex flex-col items-center justify-start text-2xl translate-x-full duration-700"
            }
          >
            <Link to="/">
              <img
                src={logo}
                alt="Logo"
                className="hidden lg:block md:max-w-xs w-56 ml-12"
              />
            </Link>

            {/* <Link
              className="text-primaryColor lg:text-sm font-bold"
              to=""
              onClick={closeNav}
            >
              Book Now
            </Link>
            <Link
              className="text-primaryColor lg:text-sm font-bold" 
              to="/"
              onClick={closeNav}
            >
              Pricing
            </Link>
            <Link
              className="text-primaryColor lg:text-sm lg:mr-12 font-bold"
              to="/"
              onClick={closeNav}
            >
              Our Story
            </Link> */}
          </div>
          <div
            className={
              color
                ? "w-full bg-secondaryColor bg-opacity-60 h-24 fixed bottom-0 right-0 z-50 lg:hidden"
                : ""
            }
          >
            {isOpen ? (
              <FaTimes
                size={30}
                onClick={() => setIsOpen(!isOpen)}
                className={`lg:hidden text-primaryColor cursor-pointer fixed bottom-9 right-10 mt-6 z-50 duration-700 ${
                  isOpen && "rotate-180 duration-300"
                }`}
              />
            ) : (
              <FaBars
                size={30}
                onClick={() => setIsOpen(!isOpen)}
                className={`lg:hidden text-primaryColor cursor-pointer fixed bottom-9 right-10 mt-6 z-50 duration-700 ${
                  isOpen && "rotate-180 duration-300"
                }`}
              />
            )}{" "}
          </div>
        </nav>
      </section>
    </>
  );
};
export default Navbar;
